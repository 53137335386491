import React from 'react'
import { Helmet } from 'react-helmet'

import { AnalyticsProvider } from '../../context/ga'
import { AuthProvider } from '../../components/AuthState/context'
import { ModalProvider } from '../../context/modal'
import { AreaProvider } from '../../context/area'
import { useScreenSize } from '../../hooks'
import { seo } from '../../seo'
import gaInit from '../../helpers/gaInit'
import { vkPixelInit, VK_PIXEL_ID } from '../../helpers/vkPixel'
import Header from '../../components/HeadSection/Header'
import MobileMenu from '../../components/MobileMenu'
import Container from '../../components/Layout/Container'
import * as styles from './layout.module.less'

const Layout = ({ children }) => {
  const isMobile = useScreenSize('mobileMenu')

  return (
    <AuthProvider>
      <ModalProvider>
        <AreaProvider>
          <AnalyticsProvider>
            <Helmet defer={false}>
              <title>
                Ипотека.Центр - сервис для заработка на ипотеке для риэлтеров и
                агентств недвижимости по партнерской программе
              </title>

              <meta name="image" content={seo.image} />
              <meta property="og:image" content={seo.image} />

              <meta property="og:title" content={seo.title} />
              <meta property="title" content={seo.title} />

              <meta name="description" content={seo.description} />
              <meta property="og:description" content={seo.description} />

              <meta property="og:url" content={seo.url} />
              <meta property="url" content={seo.url} />

              <meta name="yandex-verification" content="bccc5decc3768c84" />
              {process.env.GATSBY_ENVIRONMENT !== 'production' && (
                <meta name="robots" content="noindex" />
              )}
              <meta
                name="facebook-domain-verification"
                content="cqxdvks4lxi2ylxcw7uusmu5vzebkp"
              />

              <link rel="dns-prefetch" href="https://www.youtube.com" />
              <script>{gaInit()}</script>
              <script>{vkPixelInit()}</script>
              <noscript>
                {`<img src="https://vk.com/rtrg?p=${VK_PIXEL_ID}" style="position:fixed; left:-999px;" alt=""/>`}
              </noscript>
            </Helmet>
            <Header />
            <section className={styles.container}>
              {isMobile ? <MobileMenu /> : null}
              <Container>{children}</Container>
            </section>
          </AnalyticsProvider>
        </AreaProvider>
      </ModalProvider>
    </AuthProvider>
  )
}

export default Layout
