import React from 'react'
import Layout from '../../components/Layout'
import {
  configBanks,
  configDevelopers,
  insuranceCompanies,
} from './../../components/PartnerBanks/config'
import * as styles from './partners.module.less'

const content = {
  banks: {
    title: 'Банки',
    data: configBanks,
  },
  developers: {
    title: 'Застройщики',
    data: configDevelopers,
  },
  insuranceCompanies: {
    title: 'Страховые компании',
    data: insuranceCompanies,
  },
}

const Partners = () => {
  return (
    <Layout>
      <h1>Партнеры</h1>
      <div className={styles.partners}>
        {Object.keys(content).map((block) => (
          <div key={content[block].title}>
            <div className={styles.title}>{content[block].title}</div>
            <div>
              {content[block].data.map(({ title, logo }) => (
                <div className={styles.item} key={title}>
                  <img src={logo.small} alt={title} />
                  <div className={styles.name}>{title}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Partners
